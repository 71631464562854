<template>

<v-row class="fill-height mt-1">

    <v-col>
      <v-sheet height="64">

        <v-toolbar flat color="opcion1">

             <span class="h2 mr-5"><strong>Calendario - Promesas de pago</strong></span>

             
             <v-btn dark color="info" class="mr-3" @click="dialogNuevaPromesa=true"><v-icon>add</v-icon></v-btn>

             <v-btn dark color="success" class="mr-3" @click="listar()"><v-icon>sync</v-icon></v-btn>

            <v-divider vertical class="mx-2"></v-divider>

            
             <v-btn outlined  class="mx-2" color="grey darken-2" @click="setToday">
               Today
             </v-btn>





            <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
                <v-icon>chevron_left</v-icon>
            </v-btn>

            <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                <v-icon>chevron_right</v-icon>
            </v-btn>

             <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>

                  <v-switch class="ml-8 mt-5"
                    v-model="switch1"
                    flat
                    :label="LabelSwitch"
                    ></v-switch>

            
           

            <v-spacer></v-spacer>


           

            <v-flex lg2 xl2 md2 sm4 xs4>
                <v-select  v-model="type" :items="types" dense outlined 
                hide-details class="ma-2" label="type" right>
                </v-select>
            </v-flex>

            

               


            
            
            

    </v-toolbar>
    </v-sheet>

    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="focus"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="ActulizarTabla"
      ></v-calendar>

      <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark >
              <v-btn @click="edicion_disable=false" icon>
                <v-icon>edit</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="selectedOpen = false">
                <v-icon>close</v-icon>
              </v-btn>
            
            </v-toolbar>
            <v-card-text>
              <!--span v-html="selectedEvent.monto"></span-->
              <!--span v-html="selectedEvent.descripcion"></span-->


              <v-text-field v-model="selectedEvent.descripcion" label="Descripcion" :disabled="edicion_disable">
              </v-text-field>

              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="selectedEvent.fecha"
                    label="Fecha agendado pago"
                    prepend-icon="date_range"
                    :disabled="edicion_disable"
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="selectedEvent.fecha"
                    no-title
                    @input="menu2 = false"
                ></v-date-picker>
                </v-menu>


                <v-select
                    v-model="selectedEvent.status"
                    :items="arrayStatus"
                    :menu-props="{ top: true, offsetY: true }"
                    prepend-icon="label"
                    label="Estatus"
                    :disabled="edicion_disable"
                  ></v-select>



            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Cancelar
              </v-btn>

              <v-btn color="success" @click="ActualizarPromesaPago(selectedEvent)">
                Guardar
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-menu>




         <v-dialog v-model="dialogNuevaPromesa" persistent max-width="780">
            <v-card>
              <v-toolbar flat color="red" class="text-center">
               <v-toolbar-title class="white--text  text-center"><strong>Nueva Promesa de pago</strong></v-toolbar-title>
              </v-toolbar>

              <v-card-text>

                 <v-card-text>
                          <v-container>
                               <v-row>


                                       <v-col  cols="12" sm="6" md="6">
                                            <v-autocomplete
                                            v-model="editedItemPromesa.id_agendapago_cliente"
                                            :items="items_cliente"
                                            label="Cliente"
                                            item-value="value"
                                            item-text="text"
                                            persistent-hint
                                            prepend-icon="face">
                                            </v-autocomplete>
                                        </v-col>

                                                   

                                        <v-col cols="12" sm="6" md="6"  lg="6">
                                        

                                        <v-menu
                                            ref="menu2"
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                v-model="editedItemPromesa.fecha"
                                                label="Fecha agendado pago"
                                                prepend-icon="date_range"
                                                v-bind="attrs"
                                                v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="editedItemPromesa.fecha"
                                                no-title
                                                @input="menu2 = false"
                                            ></v-date-picker>
                                            </v-menu>

                                        </v-col>

                                        <v-col cols="12" sm="6" md="6"  lg="6">
                                                        <v-select
                                                            v-model="editedItemPromesa.status"
                                                            :items="arrayStatus"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            prepend-icon="label"
                                                            label="Estatus"
                                                        ></v-select>
                                        </v-col>


                                            <v-col  cols="12" sm="12" md="6" lg="6">
                                                      <v-text-field
                                                        v-model="editedItemPromesa.monto"
                                                        prepend-icon="money"
                                                        label="Monto pago"
                                                      ></v-text-field>
                                            </v-col>


                                                     <v-col cols="12" sm="12" md="12">
                                                      <v-textarea
                                                        name="Descripcion"
                                                        label="Descripcion"
                                                        v-model="editedItemPromesa.descripcion"
                                                        rows="1"
                                                        prepend-icon="comment"
                                                        >
                                                      </v-textarea>
                                                  </v-col>

  
                                                </v-row>
                                              </v-container>
                                            </v-card-text>

                                            <v-card-actions>
                                              <v-spacer></v-spacer>
                                              <v-btn color="blue darken-1" text @click="dialogNuevaPromesa=false">Cancelar</v-btn>
                                              <v-btn  color="success" @click="savePromesaPago()"> Guardar</v-btn>
                                            </v-card-actions>
              </v-card-text>

            </v-card>


        </v-dialog>

    </v-sheet>
 



  </v-col>
</v-row>
</template>

<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";


export default {
   components:{

    },
    data: () => ({

        arrayPagosAgendados:[],
        arreglo:[],
        switch1:true,
        LabelSwitch:'Todos',

        focus: '',
        type: 'month',
        types: ['month', 'week', 'day', '4day'],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        value: '',
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,

        events: [], //aqui se cargan las promesas de pago
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],

        edicion_disable:true,
        arrayStatus: ['Agendado', 'Exitoso', 'Cancelado'],

        menu2:false,
        menu3:false,
        date: new Date().toISOString().substr(0, 10),

        ArrayClientes:[],
        items_cliente:[],
        dialogNuevaPromesa:false,
        editedItemPromesa:{
                            id_agendapago_cliente:'',
                            fecha:new Date().toISOString().substr(0, 10),
                            descripcion:'',
                            status:'Agendado',
                            monto:0,
                            id_agendapago_ejecutivo:''
                        }
     

      }),

    computed: {
         ...mapState(['usuario', 'token']), 
    },
    watch: {

        
      switch1(val){
          if(val==true){
             this.LabelSwitch='Todos';
             this.ActulizarTabla();
          }else{
               this.LabelSwitch='Usuario';
                this.ActulizarTabla();
          }
      }

     },
    created () {

         this.listarClientes();

    },
     mounted () {
      this.$refs.calendar.checkChange();
      this.listar();
    },
    methods: {
            ...mapMutations(['mostrarLoading','ocultarLoading']),


      listar(){

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

           me.arreglo=[];
          axios.get('pagos-agendados',config
          ).then(function(response){
              console.log(response);
              me.arrayPagosAgendados=[];
              me.arreglo=response.data;

            me.arreglo.map(function(x){
                 if(me.switch1==true){
                      me.arrayPagosAgendados.push(x);
                 }  
            });

            me.ActulizarTabla();


           me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });


       },

      ActulizarTabla(){

         this.events=[];
         console.log('estado de swithc:'+this.switch1);

           let me=this;
           me.arrayPagosAgendados=[];


             me.arreglo.map(function(x){

                 if(me.switch1==true){
                      me.arrayPagosAgendados.push(x);

                       me.events.push({
                        name: x.id_agendapago_cliente.nombre_cliente,
                        start: new Date(x.fecha),
                        color:'indigo',
                        timed: true,
                        monto:x.monto,
                        descripcion:x.descripcion,
                        id:x._id,
                        fecha: new Date(x.fecha).toISOString().substr(0, 10),
                        status:x.status
                    });
                 }

                  if((me.switch1==false)&&(x.id_agendapago_ejecutivo._id==me.usuario._id)){
                      me.arrayPagosAgendados.push(x);
                      console.log('aqio bien');

                       me.events.push({
                        name: x.id_agendapago_cliente.nombre_cliente,
                        start: new Date(x.fecha),
                        color:'indigo',
                        timed: true,
                        monto:x.monto,
                        descripcion:x.descripcion,
                        id:x._id,
                        fecha: new Date(x.fecha).toISOString().substr(0, 10),
                        status:x.status
                        
                    

                    });
                 }

                    console.log('varlo activo:'+x.activo);
                 
            });
      },

       viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },

      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },


      ActualizarPromesaPago(selectItem){

          let config={headers:{token:this.token}};
           this.mostrarLoading({titulo:'Accediendo a datos'});

           let me=this;

          
           console.log(selectItem);



        axios.put(`agendapagos/${selectItem.id}`,
                { 
                  'fecha':new Date(selectItem.fecha+ " 01:00:00"),
                  'descripcion':selectItem.descripcion,
                  'status':selectItem.status,
                  'monto':parseFloat(selectItem.monto)
                  },
                  config

                  ).then(response=>{


                  console.log(response);
                   me.ocultarLoading();
                   me.dialogNuevaGestion=false;
                   me.selectedOpen = false;
                   me.listar();
        

                }).catch(e=>{
                      console.log(e)
                      this.mensaje=e
                });


      },

      //listar clientes para permitir ingresar promesa directamente

       listarClientes(){
            
            let me=this;

            let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'Accediendo a datos'});

            this.items_cliente=[];

          

            axios.get('clientes-all',
            config
            ).then(function(response){
                console.log(response);
                me.ArrayClientes=response.data;


                me.ArrayClientes.map(function(x){
                            me.items_cliente.push({'value':x._id, 'text':x.nombre_cliente});
                       });

                me.ocultarLoading();
            }).catch(function(error){
                console.log(error);
            });

      },

      savePromesaPago(){


            if(this.editedItemPromesa.id_agendapago_cliente!=null && this.editedItemPromesa.fecha!=''
             && this.editedItemPromesa.id_agendapago_cliente!='' && this.editedItemPromesa.monto){

                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'Accediendo a datos'});

                    let me=this;



                    axios.post('nueva-agendapago',
                            { 
                            
                            
                            'id_agendapago_cliente':this.editedItemPromesa.id_agendapago_cliente,
                            'fecha':new Date(this.editedItemPromesa.fecha+ " 01:00:00"),
                            'descripcion':this.editedItemPromesa.descripcion,
                            'status':this.editedItemPromesa.status,
                            'monto':parseFloat(this.editedItemPromesa.monto),
                            'id_agendapago_ejecutivo':me.usuario._id
                            },
                            config

                            ).then(response=>{


                            console.log(response);
                            me.ocultarLoading();
                            me.dialogNuevaPromesa=false;
                            me.editedItemPromesa={};
                            me.listar();
                    

                            }).catch(e=>{
                                console.log(e)
                                this.mensaje=e
                            });

             }else{
                alert('faltan campos')
             }

        


      }//fin de guardar







    }


 }
</script>